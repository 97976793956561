import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

const StyledInput = styled.input`
  display: block;
  width: 100%;
  height: 56px;
  // placeholder 가운데로 맞추기 위해 padding 조정
  padding: 0 16px;
  background: #fff;
  border: 1px solid #e5e6e8;
  font-size: 16px;

  ${breakpoint(`mobile`)} {
    font-size: 14px;
  }

  ::placeholder {
    color: #b7b8ba;
    line-height: 2;
    letter-spacing: -0.8px;
  }
  :disabled,
  :read-only {
    border: 1px solid #e5e6e8;
    opacity: 0.5;
    background: #f8f9fa;
  }
  &[readonly] {
    border: 1px solid #e5e6e8;
    opacity: 0.5;
    background: #f8f9fa;
  }
`;
export const InputCell = styled.div<{ type?: string }>`
  ${({ type }) => {
    if (type === `address`) {
      return `
      button {
        width: 26%;
        height: 56px;
        margin: 0 0 0 4%;
      }
        `;
    }
    return ``;
  }}
`;
export const InputLabel = styled.label`
  display: block;
  font-size: 16px;
`;
export const InputLabelText = styled.span<{ require?: boolean }>`
  display: inline-block;
  position: relative;
  padding-right: 8px;
  ${({ require }) => {
    if (require) {
      return `
      &::after {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        background: #1cabe2;
        border-radius: 100%;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -2px;
      }
        `;
    }
    return ``;
  }}
`;

const InputText = StyledInput;

export default InputText;
